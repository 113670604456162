.flexDiv {
    display: flex;
    flex-direction: column;
}
.banner-container{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 905px ; /* 80% of 1131px in design */
    flex-direction: column;
}
.ThemeONEhomeContent{
    margin-top: 905px;
}
.ThemeTWOhomeContent{
    margin-top: 905px;
}
.ThemeTHREEhomeContent{
    margin-top: 905px;
}
.ThemeFOURhomeContent{
    margin-top: 905px;
}
.banner-top{
    min-height: 650px ; /* 80% of 749px in design */
    flex-direction: column;
    padding-top: 120px;
    padding-left: 30px;
    padding-bottom: 30px;
    padding-right: 30px;
    z-index: 0;
}

.banner-top-1 {
    flex: 1;
    gap: 30px;
    min-height: 330px;
    max-width: 500px
    /* justify-content: space-between; */
}
.banner-bottom{
    height: 306px ; /* 80% of 382px in design */
    z-index: -1;
}

.bannerProduct {
    height: 420px !important
}

.product-large-2-info{
    padding: 20px 0;
    flex-direction: row;
}

@media screen and (min-width: 768px) {
    .banner-container{
        height: 1052px; /* 80% of 1315px in design */
    }
    .ThemeONEhomeContent{
        margin-top: 1052px;
    }
    .ThemeTWOhomeContent{
        margin-top: 900px;
    }
    .ThemeTHREEhomeContent{
        margin-top: 980px;
    }
    .ThemeFOURhomeContent{
        margin-top: 1100px;
    }
    .banner-top{
        height: 718px ; /* 80% of 898px in design */
        padding-top: 220px;
        padding-left: 60px;
        padding-right: 60px;
        padding-bottom: 60px;
    }
    .banner-top-1{
        min-height: 260px;
        margin-bottom: 30px;
    }
    .banner-bottom{
        height: 334px ; /* 80% of 417px in design */
    }
    .bannerProduct{
        height: 420px !important
    }
}

@media screen and (min-width: 1024px) {
    .homeContentWrapper {
        margin-top: 100px;
    }

    .banner-container{
        height: 720px; /* 80% of 900px in design */
    }
    .ThemeONEhomeContent{
        margin-top: 720px;
    }
    .ThemeTWOhomeContent{
        margin-top: 600px;
    }
    .ThemeTHREEhomeContent{
        margin-top: 500px;
    }
    .ThemeFOURhomeContent{
        margin-top: 1150px;
    }
    .banner-top {
        height: 560px ;/* 80% of 700px in design */
        flex-direction: row ;
        flex: 1;
        padding-left: 100px;
        /* padding-bottom: 100px; */
    }
    .banner-top-1 { flex: 0.5; max-width: 100%; margin-bottom: 60px }
    .banner-top-2 { flex: 0.5;}
    
    .banner-bottom {
        height: 160px /* 80% of 200px in design  */
    }
    .bannerProduct{
        height: 540px !important;
    }
    .product-large-2-points{
        align-self: flex-end;
    }
    .product-large-2-info{
        flex-direction: column;
    }
}

@media screen and (min-width: 1400px) {
    .ThemeFOURhomeContent{
        margin-top: 840px;
    }
}

@media screen and (max-width: 1024px) {
    .banner-top{
        gap: 11rem;
    }
    .banner-top-2{
        align-self: center;
        width: 80vw;
    }
    .product-large-2-info{
        align-items: center;
    }
    .product-large-2-points-button{
        min-width: 100px;
        align-items: center;
        justify-content: center;
    }
}

@media screen and (min-width: 1920px) {
    .banner-top-1 { flex: 0.8; max-width: 100% }
}

@media screen and (min-width: 1751px) and (max-width: 1919px) {
    .banner-top-1 { flex: 0.7; max-width: 100% }
}
@media screen and (min-width: 1200px) and (max-width: 1750px) {
    .banner-top-1 { flex: 0.6; max-width: 100% }
}

@media screen and (min-width: 700px) and (max-width: 1023px) {
    .homeContentWrapper {
        margin-top: 50vw;
    }
}

@media screen and (min-width: 600px) and (max-width: 700px) {
    .homeContentWrapper {
        margin-top: 25vw;
    }
}
@media screen and (min-width: 500px) and (max-width: 600px) {
    .homeContentWrapper {
        margin-top: 15vw;
    }
}
@media screen and (min-width: 400px) and (max-width: 500px) {
    .homeContentWrapper {
        margin-top: 40vw;
    }
    .banner-top-2{
        width: 80vw;
    }
}
@media screen and (max-width: 400px) {
    .homeContentWrapper {
        margin-top: 30vw;
    }
    .banner-top-2{
        width: 80vw;
    }
}