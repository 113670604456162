.page-wrapper {
    position: relative;
    overflow: hidden;
    width: 100%;
    max-width: 1920px;
    margin: auto;
    background-color: white;
    box-shadow: rgb(34 34 34 / 15%) 0px 0px 100px;     
}

@media screen and (min-width: 1920px) {
}

.content-wrapper {
    padding-left: 10%;
    padding-right: 10%;
    display: flex;
    flex-direction: column;
    /* margin-top: 75px; */
}

@media screen and (min-width: 1024px) {
    .content-wrapper {
        padding-left: 80px;
        padding-right: 80px;
    }
}

@media screen and (min-width: 1280px) {
    .content-wrapper {
        padding-left: 100px;
        padding-right: 100px;
    }
}
