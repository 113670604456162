
.basket-faq-block {
    padding-left: 10%;
    padding-right: 10%;
    display: flex;
    flex-direction: column;
    margin-top: 75px;
    gap: 20px;
}
.basket-faq-block-content{
    display: flex;
    flex-direction: column;
    gap: 20px
}

@media screen and (min-width: 1024px) {
    .basket-faq-block {
        padding-left: 80px;
        padding-right: 80px;
    }
    .basket-faq-block-content{
        flex-direction: row;
        justify-content: space-between;
    }
}

@media screen and (min-width: 1280px) {
    .basket-faq-block {
        padding-left: 100px;
        padding-right: 100px;
    }
}

.footerBottomMain {
    padding: 100px 40px;
}

.footerBottom {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 80px;
}

.footermenulist {
    display: flex;
    flex-direction: row;
    gap: 50px;
}

.iconItem {
    justify-content: center;
}

@media screen and (min-width: 425px) and (max-width: 625px){
    .footermenulist {
        gap: 100px;
    }
}

@media screen and (min-width: 625px) {
    .footerBottomMain {
        padding: 100px 70px;
    }

    .footerBottom {
        align-items: flex-start;
        flex-direction: row;
        justify-content: space-between;
    }

    .iconItem {
        justify-content: flex-start;
    }
}

@media screen and (min-width: 768px) {
    .footerBottomMain {
        padding: 100px 80px;
    }
}

@media screen and (min-width: 960px) {
    .footerBottomMain {
        padding: 100px 90px;
    }

    .footermenulist {
        gap: 100px;
    }
}

@media screen and (min-width: 1280px) {
    .footerBottomMain {
        padding: 100px 100px;
    }
}

.footerTop {
    padding: 40px;
}

.footerBtnCol {
    justify-content: flex-start;
    margin-top: 30px;
}

@media screen and (min-width: 400px) {
    .footerTop {
        padding: 50px;
    }
}

@media screen and (min-width: 768px) {

    .footerTop {
        padding: 80px;
    }
    
    .footerBtnCol {
        justify-content: flex-end;
        margin-top: 0;
    }
}

@media screen and (min-width: 960px) {
    .footerTop {
        padding: 80px;
    }
}

@media screen and (min-width: 1280px) {
    .footerTop {
        padding: 90px;
    }
}

@media screen and (min-width: 1440px) {
    .footerTop {
        padding: 100px;
    }
}




