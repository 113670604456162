@import url(https://fonts.googleapis.com/css2?family=Barlow:wght@100;200;300;400;500;600;700;800;900&family=Dancing+Script:wght@400;500;600;700&family=Lora:wght@400;500;600;700&family=Montserrat:wght@100;200;300;400;600;700&family=Poppins:wght@100;200;300;400;500;600;700;800&family=Work+Sans:wght@100;300;400;500;600;700&display=swap);
/* 
@import url('https://fonts.googleapis.com/css?family=Poppins:regular,bold,italic&subset=latin,latin-ext');
@import url('https://fonts.googleapis.com/css?family=Lora:regular,bold,italic&subset=latin,latin-ext');
@import url('https://fonts.googleapis.com/css?family=Work Sans:regular,bold,italic&subset=latin,latin-ext');
@import url('https://fonts.googleapis.com/css?family=Montserrat:regular,bold,italic&subset=latin,latin-ext');
@import url('https://fonts.googleapis.com/css?family=Dancing Script:regular,bold,italic&subset=latin,latin-ext'); 
*/

body {
  font-family: 'Poppins', cursive;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

textarea:focus,
input[type="text"]:focus,
input[type="password"]:focus,
input[type="datetime"]:focus,
input[type="datetime-local"]:focus,
input[type="date"]:focus,
input[type="month"]:focus,
input[type="time"]:focus,
input[type="week"]:focus,
input[type="number"]:focus,
input[type="email"]:focus,
input[type="url"]:focus,
input[type="search"]:focus,
input[type="tel"]:focus,
input[type="color"]:focus,
.uneditable-input:focus {   
  box-shadow: none;
  outline: none;
}

*{ overflow: visible }
button, form 
{overflow: hidden;}

.LoginContainer {
  flex-direction: column;
  align-items: center;
  justify-content: center;
  display: flex;
}

.offcanvas-end{
  border-left: none !important;
}

@media only screen and (max-width: 500px) {
  .offcanvas-end{
    width: 100% !important;
  }
}

/* 
 * Placeholder CSS 
 */

/* Generic */
::-webkit-input-placeholder {
  /* text-shadow: 0px 1px black; */
  letter-spacing: 0.5px;
  color: var(--placeholder-color) !important;
  opacity: 1 !important; 
}
:-ms-input-placeholder {
  /* text-shadow: 0px 1px black; */
  letter-spacing: 0.5px;
  color: var(--placeholder-color) !important;
  opacity: 1 !important; 
}
::placeholder {
  /* text-shadow: 0px 1px black; */
  letter-spacing: 0.5px;
  color: var(--placeholder-color) !important;
  opacity: 1 !important; 
}

/* Internet Explorer 10-11 */
:-ms-input-placeholder { 
 /* text-shadow: 0px 1px black; */
  letter-spacing: 0.5px;
  color: var(--placeholder-color) !important;
}

/* Microsoft Edge */
::-ms-input-placeholder { 
 /* text-shadow: 0px 1px black; */
  letter-spacing: 0.5px;
  color: var(--placeholder-color) !important;
}

/* Chrome/Opera/Safari */
::-webkit-input-placeholder { 
  /* text-shadow: 0px 1px black; */
  letter-spacing: 0.5px;
  color: var(--placeholder-color) !important;
}

/* Firefox 19+ */
::-moz-placeholder { 
  /* text-shadow: 0px 1px black; */
  letter-spacing: 0.5px;
  color: var(--placeholder-color) !important;
}
.large-screen-only {
  display: flex;
}
@media screen and (max-width: 1024px) {
  .large-screen-only {
    display: none;
  }
}

/* CSS specific to iOS Safari */ 
@supports (-webkit-touch-callout: none) {
  .basket-quickview-ios-safari-padding {
    padding-bottom: 200px !important;
  }
  .basket-quickview-ios-safari-bottom {
    bottom: 70px !important;
  }
}

.hide-scroll::-webkit-scrollbar {
  display: none;
}

.hide-scroll {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.modal-content{
  max-width: 90vw !important;
  margin: auto;
}
.lazy-visible {
  display: flex;
}
.lazy-placeholder{
  background-image: url("https://picsum.photos/200/300");
}

.horizontal-scroll-wrapper {
  padding-left: 10%;
  margin-left: -10%;
  margin-right: -10%;
}

@media screen and (min-width: 1280px) {
  .horizontal-scroll-wrapper {
      padding-left: 100px;
      margin-left: -100px;
      margin-right: -100px;
  }
}

.basket-faq-block {
    padding-left: 10%;
    padding-right: 10%;
    display: flex;
    flex-direction: column;
    margin-top: 75px;
    grid-gap: 20px;
    gap: 20px;
}
.basket-faq-block-content{
    display: flex;
    flex-direction: column;
    grid-gap: 20px;
    gap: 20px
}

@media screen and (min-width: 1024px) {
    .basket-faq-block {
        padding-left: 80px;
        padding-right: 80px;
    }
    .basket-faq-block-content{
        flex-direction: row;
        justify-content: space-between;
    }
}

@media screen and (min-width: 1280px) {
    .basket-faq-block {
        padding-left: 100px;
        padding-right: 100px;
    }
}

.footerBottomMain {
    padding: 100px 40px;
}

.footerBottom {
    display: flex;
    flex-direction: column;
    align-items: center;
    grid-gap: 80px;
    gap: 80px;
}

.footermenulist {
    display: flex;
    flex-direction: row;
    grid-gap: 50px;
    gap: 50px;
}

.iconItem {
    justify-content: center;
}

@media screen and (min-width: 425px) and (max-width: 625px){
    .footermenulist {
        grid-gap: 100px;
        gap: 100px;
    }
}

@media screen and (min-width: 625px) {
    .footerBottomMain {
        padding: 100px 70px;
    }

    .footerBottom {
        align-items: flex-start;
        flex-direction: row;
        justify-content: space-between;
    }

    .iconItem {
        justify-content: flex-start;
    }
}

@media screen and (min-width: 768px) {
    .footerBottomMain {
        padding: 100px 80px;
    }
}

@media screen and (min-width: 960px) {
    .footerBottomMain {
        padding: 100px 90px;
    }

    .footermenulist {
        grid-gap: 100px;
        gap: 100px;
    }
}

@media screen and (min-width: 1280px) {
    .footerBottomMain {
        padding: 100px 100px;
    }
}

.footerTop {
    padding: 40px;
}

.footerBtnCol {
    justify-content: flex-start;
    margin-top: 30px;
}

@media screen and (min-width: 400px) {
    .footerTop {
        padding: 50px;
    }
}

@media screen and (min-width: 768px) {

    .footerTop {
        padding: 80px;
    }
    
    .footerBtnCol {
        justify-content: flex-end;
        margin-top: 0;
    }
}

@media screen and (min-width: 960px) {
    .footerTop {
        padding: 80px;
    }
}

@media screen and (min-width: 1280px) {
    .footerTop {
        padding: 90px;
    }
}

@media screen and (min-width: 1440px) {
    .footerTop {
        padding: 100px;
    }
}





.drawer {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 480px;
    background: white;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    max-height: 100vh;
    position: absolute;
    right: -480px;
    top: 0;
    box-shadow: 0px 0px 100px rgba(34, 34, 34, 0.25);
    transition-duration: 0.2s, 0.5s;
    transition-property: right, opacity;
    transition-timing-function: ease-out;
    overflow: hidden;
    opacity: 0;
    z-index: 3;
}

.drawer.show {
    opacity: 1;
    overflow-y: scroll;
    width: 100%;
    right: 0;
    max-width: 480px;
    transition-duration: 0.2s, 0.5s;
    transition-property: right, opacity;
    transition-timing-function: ease-in-out;
    -ms-scroll-chaining: none;
        overscroll-behavior: contain
}

.page-wrapper.overlay {
    position: fixed !important;
    width: 100% !important;
    height: 100% !important;
    top: 0 !important;
    left: 0 !important;
    right: 0 !important;
    bottom: 0 !important;
}
.header-left{
    width: 40%;
}
.header-right{
    width: 60%;
}
.mobile-header-logo{
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    align-items: center;
}
.basket_buttons{
    width:100%;
    grid-gap: 15px;
    gap: 15px;
}
.basket_buttons>button:nth-child(1){
    width:45%;
    justify-content: center;
}
.basket_buttons>button:nth-child(2){
    width:55%;
    justify-content: center;
}

@media (max-width: 768px) {
    .header-left{
        width: 100vw;
    }
    .header-right{
        width: 100vw;
    }
}
/* Header Theme 1 styles */
.points-notification-block {
    display: flex; 
    flex-direction: column;
    padding: 10px;
    position: absolute;
    width: 100%
}
@media (min-width: 768px) {
    .points-notification-block {
        width: 60%;
        padding: 40px;
    }
}

@media (max-width: 360px) {
    .mobileNav {
        width: calc(100% - 80px);
    }

    .navbar-expand .navbar-nav .nav-link {
        padding-left: 0.3rem;
    }
}

@media (max-width: 427px) {
    .mobileNav {
        width: calc(100% - 90px);
        justify-content: space-between; 
        display: flex; 
        flex-direction: row;
    }

    .navbar-expand .navbar-nav .nav-link {
        padding-right: 0;
    }

    .containerFluidCustom {
        padding-left: 6px;
    }
}

@media (max-width: 576px) {
    .mobileNav {
        justify-content: flex-end; 
        display: flex; 
        flex: 1 1;
    }
    .mobile-header-logo{
        padding: 5px;
    }
}
/* CustomInput */
#select-multiple-checkbox.css-1rxz5jq-MuiSelect-select-MuiInputBase-input-MuiInput-input:focus{
        background-color: white !important;
}
.css-1kty9di-MuiFormLabel-root-MuiInputLabel-root.Mui-focused{
    color: inherit !important;
}
.page-wrapper {
    position: relative;
    overflow: hidden;
    width: 100%;
    max-width: 1920px;
    margin: auto;
    background-color: white;
    box-shadow: rgb(34 34 34 / 15%) 0px 0px 100px;     
}

@media screen and (min-width: 1920px) {
}

.content-wrapper {
    padding-left: 10%;
    padding-right: 10%;
    display: flex;
    flex-direction: column;
    /* margin-top: 75px; */
}

@media screen and (min-width: 1024px) {
    .content-wrapper {
        padding-left: 80px;
        padding-right: 80px;
    }
}

@media screen and (min-width: 1280px) {
    .content-wrapper {
        padding-left: 100px;
        padding-right: 100px;
    }
}

.save_cpr:disabled{
    background-color:#d1d1d1;
    color:#a6a6a6;
    border-radius: 5px;
}

.postcardWrapper {
    padding-bottom: 75%;
}

@media screen and (max-width: 425px) {
    .postcardWrapper {
        padding-bottom: 100%;
    }
}
.grid-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: auto 1fr auto;
  grid-template-areas: "header header header" "title title footer" "main main main";
}

@media screen and (max-width: 500px) {
  .grid-container {
    grid-template-columns: 1fr;
    grid-template-areas: "header" "title" "main" "footer";
  }
}



.grid-item {
  padding: 3.5em 1em;
  font-size: 1em;
  font-weight: 700;
}

.main {
  grid-area: main;
  padding: 0;
  overflow-x: scroll;
  overflow-y: hidden;
}

.items {
  position: relative;
  width: 100%;
  overflow-x: scroll;
  overflow-y: hidden;
  white-space: nowrap;
  transition: all 0.2s;
  -webkit-transform: scale(1);
          transform: scale(1);
  will-change: transform;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  cursor: pointer;
  padding-left: 2px;
}

.items.active {
  cursor: grabbing;
  cursor: -webkit-grabbing;
}

.item {
  display: inline-block;
  min-height: 250px;
  margin: 1.5rem 40px 2rem 0rem;
}
.item:last-child{
  margin-right: 2px;
}

@media screen and (max-width: 500px) {
  .item {
    min-height: 200px;
    min-width: 200px;
  }
}

@media screen and (min-width: 720px) {
  .item {
    margin: 2rem 40px 2rem 0rem;
  }
}


.hs {
  display: grid;
  grid-gap: calc(var(--gutter) / 2);
  grid-template-columns: 10px;
  grid-template-rows: minmax(150px, 1fr);
  grid-auto-flow: column;
  grid-auto-columns: calc(50% - var(--gutter) * 2);

  overflow-x: scroll;
  -ms-scroll-snap-type: x proximity;
      scroll-snap-type: x proximity;
  padding-bottom: calc(0.75 * var(--gutter));
  margin-bottom: calc(-0.25 * var(--gutter));
}

.hs:before,
.hs:after {
  content: "";
  width: 10px;
}

/* Range Slider CSS */

.horizontalScrollBlock input[type="range"] {
  -webkit-appearance: none;
}

.horizontalScrollBlock input[type="range"]::-webkit-slider-runnable-track {
  width: 100%;
  height: 4px;
  cursor: pointer;
  background: #e8e8e8;
}

.horizontalScrollBlock input[type="range"]::-moz-range-track {
  width: 100%;
  height: 4px;
  cursor: pointer;
  background: #e8e8e8;
}

.horizontalScrollBlock input[type="range"]::-ms-track {
  width: 100%;
  height: 4px;
  cursor: pointer;
  background: transparent;
  border-color: transparent;
  border-width: 16px 0;
  color: transparent;
}
.horizontalScrollBlock input[type="range"]::-ms-fill-lower {
  border-radius: 2.6px;
}
.horizontalScrollBlock input[type="range"]:focus::-ms-fill-lower {
  background: #e8e8e8;
}
.horizontalScrollBlock input[type="range"]::-ms-fill-upper {
  background: #e8e8e8;
  border-radius: 2.6px;
}

/* Special styling for WebKit/Blink */
.horizontalScrollBlock input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  border: none;
  height: 4px;
  /* width: 100px; */
  /* border-radius: 3px; */
  background: #222222;
  cursor: pointer;
  margin-top: 0px; /* You need to specify a margin in Chrome, but in Firefox and IE it is automatic */
}

/* All the same stuff for Firefox */
.horizontalScrollBlock input[type="range"]::-moz-range-thumb {
  border: none;
  height: 4px;
  /* width: 100px; */
  /* border-radius: 3px; */
  background: #222222;
  cursor: pointer;
}

/* All the same stuff for IE */
.horizontalScrollBlock input[type="range"]::-ms-thumb {
  border: none;
  height: 4px;
  /* width: 100px; */
  /* border-radius: 3px; */
  background: #222222;
  cursor: pointer;
}

.custom-carousel .carousel-control-prev,
.custom-carousel .carousel-control-next 
{
    opacity: 0.85;
    transition: 'opacity .15s ease';
}

.custom-carousel .carousel-control-prev:hover,
.custom-carousel .carousel-control-next:hover 
{
    opacity: 1;
}

.flexDiv {
    display: flex;
    flex-direction: column;
}
.banner-container{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 905px ; /* 80% of 1131px in design */
    flex-direction: column;
}
.ThemeONEhomeContent{
    margin-top: 905px;
}
.ThemeTWOhomeContent{
    margin-top: 905px;
}
.ThemeTHREEhomeContent{
    margin-top: 905px;
}
.ThemeFOURhomeContent{
    margin-top: 905px;
}
.banner-top{
    min-height: 650px ; /* 80% of 749px in design */
    flex-direction: column;
    padding-top: 120px;
    padding-left: 30px;
    padding-bottom: 30px;
    padding-right: 30px;
    z-index: 0;
}

.banner-top-1 {
    flex: 1 1;
    grid-gap: 30px;
    gap: 30px;
    min-height: 330px;
    max-width: 500px
    /* justify-content: space-between; */
}
.banner-bottom{
    height: 306px ; /* 80% of 382px in design */
    z-index: -1;
}

.bannerProduct {
    height: 420px !important
}

.product-large-2-info{
    padding: 20px 0;
    flex-direction: row;
}

@media screen and (min-width: 768px) {
    .banner-container{
        height: 1052px; /* 80% of 1315px in design */
    }
    .ThemeONEhomeContent{
        margin-top: 1052px;
    }
    .ThemeTWOhomeContent{
        margin-top: 900px;
    }
    .ThemeTHREEhomeContent{
        margin-top: 980px;
    }
    .ThemeFOURhomeContent{
        margin-top: 1100px;
    }
    .banner-top{
        height: 718px ; /* 80% of 898px in design */
        padding-top: 220px;
        padding-left: 60px;
        padding-right: 60px;
        padding-bottom: 60px;
    }
    .banner-top-1{
        min-height: 260px;
        margin-bottom: 30px;
    }
    .banner-bottom{
        height: 334px ; /* 80% of 417px in design */
    }
    .bannerProduct{
        height: 420px !important
    }
}

@media screen and (min-width: 1024px) {
    .homeContentWrapper {
        margin-top: 100px;
    }

    .banner-container{
        height: 720px; /* 80% of 900px in design */
    }
    .ThemeONEhomeContent{
        margin-top: 720px;
    }
    .ThemeTWOhomeContent{
        margin-top: 600px;
    }
    .ThemeTHREEhomeContent{
        margin-top: 500px;
    }
    .ThemeFOURhomeContent{
        margin-top: 1150px;
    }
    .banner-top {
        height: 560px ;/* 80% of 700px in design */
        flex-direction: row ;
        flex: 1 1;
        padding-left: 100px;
        /* padding-bottom: 100px; */
    }
    .banner-top-1 { flex: 0.5 1; max-width: 100%; margin-bottom: 60px }
    .banner-top-2 { flex: 0.5 1;}
    
    .banner-bottom {
        height: 160px /* 80% of 200px in design  */
    }
    .bannerProduct{
        height: 540px !important;
    }
    .product-large-2-points{
        align-self: flex-end;
    }
    .product-large-2-info{
        flex-direction: column;
    }
}

@media screen and (min-width: 1400px) {
    .ThemeFOURhomeContent{
        margin-top: 840px;
    }
}

@media screen and (max-width: 1024px) {
    .banner-top{
        grid-gap: 11rem;
        gap: 11rem;
    }
    .banner-top-2{
        align-self: center;
        width: 80vw;
    }
    .product-large-2-info{
        align-items: center;
    }
    .product-large-2-points-button{
        min-width: 100px;
        align-items: center;
        justify-content: center;
    }
}

@media screen and (min-width: 1920px) {
    .banner-top-1 { flex: 0.8 1; max-width: 100% }
}

@media screen and (min-width: 1751px) and (max-width: 1919px) {
    .banner-top-1 { flex: 0.7 1; max-width: 100% }
}
@media screen and (min-width: 1200px) and (max-width: 1750px) {
    .banner-top-1 { flex: 0.6 1; max-width: 100% }
}

@media screen and (min-width: 700px) and (max-width: 1023px) {
    .homeContentWrapper {
        margin-top: 50vw;
    }
}

@media screen and (min-width: 600px) and (max-width: 700px) {
    .homeContentWrapper {
        margin-top: 25vw;
    }
}
@media screen and (min-width: 500px) and (max-width: 600px) {
    .homeContentWrapper {
        margin-top: 15vw;
    }
}
@media screen and (min-width: 400px) and (max-width: 500px) {
    .homeContentWrapper {
        margin-top: 40vw;
    }
    .banner-top-2{
        width: 80vw;
    }
}
@media screen and (max-width: 400px) {
    .homeContentWrapper {
        margin-top: 30vw;
    }
    .banner-top-2{
        width: 80vw;
    }
}
.custom_carousel .custom-control-prev,
.custom_carousel .custom-control-next 
{
    opacity: 0.85;
    transition: .15s ease;
}
.custom-control-prev{
    position:absolute;
    margin-top:590px;
    margin-left:20px;
    z-index:1;
    width:15%;
    color:#fff;
    opacity:.5;
}
.custom-control-next{
    position:absolute;
    margin-top:590px;
    margin-left:90px;
    z-index:1;
    width:15%;
    color:#fff;
    opacity:.5;
}
.custom_carousel .custom-control-prev:hover,
.custom_carousel .custom-control-next:hover 
{
    opacity: 1;
}
.banner-container-banner2 {
    display: flex;
    flex-direction: row;
    top:0;
    position: absolute;
    left: 0;
    height: 850px;
    width: 100%;
}
.banner-div1 {
    width: 100%;
    left: 0%;
    right: 50%;
    top: 0%;
    bottom: 0%;
    height: 850px;
}
.theme2-banner-text{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-self: center;
    padding: 0 10%;
    grid-gap: 2rem;
    gap: 2rem;
    padding-top: 50px;
    margin-bottom: 60px;
}
.banner-div2 {
    height: 850px;
    left: 0%;
    top: 0%;
    width:100%;
}
.banner2-div {
    width: 320px;
    position: absolute;
    bottom:10%;
    right:6%;
    display: flex;
    flex-direction: column;
    grid-gap: 0.5rem;
    gap: 0.5rem;
    justify-content: space-between;
}
.banner2-heading {
    cursor: pointer;
    width:320px;
    white-space: normal;
    display: flex;
    /* display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical; */
}
.banner2-paragraph {
    width:320px;
    margin-top: 20px;
    height: -webkit-max-content;
    height: max-content;
    white-space: normal;
    display: flex;
    /* display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical; */

}
.banner2-button {
    width:320px;
    margin-top: 40px;
}
.banner-container {
    display: flex;
    width: 100%;
}

@media screen and (min-width: 1400px) {
    .banner2-div {
    max-width: 300px;
    }
}

@media screen and (min-width: 1024px) {
.theme2-banner-text {
    padding-left: 80px;
    padding-right: 80px;
    }
}

@media screen and (max-width: 1024px) {
.banner-container-banner2 {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-bottom: 1px;
    }
}

@media screen and (min-width:600px) and (max-width: 1023.9px) {
.custom-control-prev{
    margin-top:76%;
    margin-left:50px;
    }
.custom-control-next{
    margin-top:76%;
    margin-left:120px;
    }
.banner-div1{
    width: 100%;
    height:600px;
    }
.banner-div2{
    width: 100%;
    height:600px;
    }
.theme2-banner-text{
    margin-top: 15%;
    }
.banner2-div{
    width: 307px;
    position: absolute;
    bottom:80px;                                                                
    right:90px;
    }
}

@media screen and (min-width:500px) and (max-width: 600px) {
.custom-control-prev{
    margin-top:84%;
    margin-left:39%;
    }
.custom-control-next{
    margin-top:84%;
    margin-left:52%;
    }
.banner-div1{
    width: 100%;
    height:560px;
    }
.banner-div2{
    width: 100%;
    height:667px;
    }
.theme2-banner-text{
    margin-top: 15%;
    }
.banner2-div{
    margin:auto;
    width: 315px;
    position: absolute;
    bottom:120px;
    right:30px;
    left:30px;
    }
}

 @media screen and (min-width:400px) and (max-width:500px) {
.custom-control-prev{
    margin-top:98%;
    margin-left:37%;
    }
.custom-control-next{
    margin-top:98%;
    margin-left:53%;
    }
.banner-div1{
    width: 100%;
    height:560px;
    }
.banner-div2{
    width: 100%;
    height:667px;
    }
.theme2-banner-text{
    margin-top: 15%;
    }
.banner2-div{
    margin:auto;
    width: 315px;
    position: absolute;
    bottom:120px;
    right:30px;
    left:30px;
    }
} 

@media screen and (max-width:400px){
.custom-control-prev{
    margin-top:110%;
    margin-left:33%;
    }
.custom-control-next{
    margin-top:110%;
    margin-left:53%;
    }
.banner-div1{
    width: 100%;
    height:560px;
    }
.banner-div2{
    width: 100%;
    height:667px;
    }
.theme2-banner-text{
    margin-top: 15%;
    }
.banner2-div{
    margin:auto;
    width: 315px;
    position: absolute;
    bottom:100px;
    right:30px;
    left:30px;
    }
}


.banner-container-banner3 {
    display: flex;
    flex-direction: row;
    position: absolute;
    left: 0;
    top:80px;
    height: 600px;
    width: 100%;
}

.banner3-div1 {
    width: 100%;
    height: 100%;
}

.banner3-div2 {
    width: 100%;
    height: 100%;
}

.theme3-banner-text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-self: center;
    padding: 0 10%;
    grid-gap: 2rem;
    gap: 2rem;
    padding-top: 50px;
}

@media screen and (min-width: 1024px) {
    .theme3-banner-text {
        padding-left: 80px;
        padding-right: 80px;
    }
}

@media screen and (max-width: 1024px) {
    .banner-container-banner3 {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 1024px;
        padding-bottom: 1px;
    }

    .banner3-div1 {
        width: 100%;
        height: 500px;
    }

    .banner3-div2 {
        width: 100%;
        height: 524;
    }

    .theme3-banner-text {
        margin-top: 12%;
        margin-bottom: 100px;
    }

    .carousel-inner {
        height: 100%;
    }
}

@media screen and (max-width: 480px) {
    .theme3-banner-text {
        margin-top: 15%;

    }

    .theme3-banner-text buttton {
        margin-bottom: 100px;
    }
}

@media screen and (min-width:480px) and (max-width: 576px) {
    .theme3-banner-text {
        margin-top: 15%;
    }
}
svg {overflow: visible;}

/* Button 1 */
.buttonMedium1, .roundIconButton1{
    background-color : #E56B2E
}
.buttonMedium1:hover, .roundIconButton1:hover{
    background-color : #B25324
}

/* Button 2 */
.buttonMedium2, .roundIconButton2{
    background-color : #000000
}
.buttonMedium2:hover, .roundIconButton2:hover{
    background-color : #E66B2E
}

/* Button 3 */
.buttonMedium3, .roundIconButton3{
    color: #222222;
}
.buttonMedium3:hover, .roundIconButton3:hover{
    color : #E56B2E
}
.buttonMedium3 path, .roundIconButton3 path{
    fill: #222222
}
.buttonMedium3:hover path, .roundIconButton3:hover path{
    fill : #E56B2E
}

/* Button 4 */
.buttonLarge4, .buttonMedium4, .roundIconButton4{
    color: #222222;
    border: 1px solid #BFBFBF;
}
.buttonLarge4:hover, .buttonMedium4:hover, .roundIconButton4:hover{
    color : #E56B2E;
    border: 1px solid #E56B2E;
}
.buttonLarge4 path, .buttonMedium4 path, .roundIconButton4 path{
    fill: #222222
}
.buttonLarge4:hover path, .buttonMedium4:hover path, .roundIconButton4:hover path{
    fill : #E56B2E
}

/* Icon Button 1 */
.iconButtonLarge1, .iconButtonMedium1{
    background-color: #FFFFFF;
    border: 1px solid #BFBFBF;
}
.iconButtonLarge1:hover, .iconButtonMedium1:hover{
    background-color: #FF4747;
    border: none;
}
.iconButtonLarge1:hover path, .iconButtonMedium1:hover path{
    fill: #FFFFFF;
    stroke: #FFFFFF;
}
.HighlightedProductBlock {
    display: flex;
    flex-direction: column;
    grid-gap: 5rem;
    gap: 5rem;
    position: relative;
}

.highlightedProductText { 
    display: flex;
    flex-direction: column;
    grid-gap: 2rem;
    gap: 2rem;
    justify-content: center;
    align-items: flex-start;
    width: 34.2vw;
    max-width: 660px;
}
.HighlightedProductBlock .highlightedProduct{
    box-shadow: 0px 0px 3px 1px rgba(34, 34, 34, 0.15);
    max-width: 660px;
}


@media screen and (max-width: 1024px) {
    .HighlightedProductBlock .highlightedProduct {
        width: 90vw;
        max-width: 100%;
    }
    .highlightedProductText {
        width: 100%;
        margin-bottom: 2rem;
    }
}
@media screen and (min-width: 1024px) {
    .HighlightedProductBlock {
        flex-direction: row;
        grid-gap: 15%;
        gap: 15%;
    }
}


/* 
@media screen and (max-width: 500px) {
    .HighlightedProductBlock {
        margin-top: 700px;
    }
}

@media screen and (min-width: 500px) and (max-width: 768px) {
    .HighlightedProductBlock {
        margin-top: 700px;
    }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
    .HighlightedProductBlock {
        margin-top: 550px;
    }
} 
*/

.countDownModal {
    display: flex;
    flex-direction: column;
}

.countDownTimer {
    display: flex;
    flex-direction: row;
    position: absolute;
    grid-gap: 20px;
    gap: 20px;
    top: 0;
    left: 0;
    padding: 20px 30px;
}

.countDownButtonM {
    display: flex;
    text-decoration: none;
}

.countDownButtonL {
    display: none;
    text-decoration: none;
}

.countDownTimeTitle {
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;
    min-width: 35px;
    text-align: center;
}

.countDownTimerContent {
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 24px;
}

.countDownTimerContentBig {
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 24px;
    display: none;
}

.countDownModal .countDownBox {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: end;
    padding: 40px;
    padding-top: 186px;
}

.countDownModal .countDownBoxFalse {
    padding-top: 40px;
}

.countDownTitle {
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;
    margin-bottom: 10px;
    white-space: normal;
}

.competitionStatus {
    margin-bottom: 5px;
}

.countDownContent {
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 24px;
    margin-bottom: 20px;
    white-space: normal;
}

.countDownButton {
    font-size: 14px;
}

@media screen and (min-width: 471px) {

    .countDownTitle {
        font-size: 30px;
        line-height: 44px;
        margin-bottom: 20px;
    }

    .countDownContent {
        font-size: 18px;
        line-height: 28px;
        margin-bottom: 40px;
    }

    .countDownTimeTitle {
        font-style: normal;
        font-weight: 600;
        font-size: 40px;
        line-height: 54px;
    }

    .countDownTimer {
        grid-gap: 45px;
        gap: 45px;
        padding: 20px 35px;
    }

    .countDownTimerContent {
        display: none;
    }

    .countDownTimerContentBig {
        display: block;
    }


    .countDownButtonM {
        display: none;
    }

    .countDownButtonL {
        display: flex;
    }
}

@media screen and (min-width: 642px) and (max-width: 769px) {
    .countDownModal .countDownBox {
        padding: 200px 172px 40px 40px;
    }

    .countDownModal .countDownBoxFalse {
        padding-Top: 50px;
    }
}


@media screen and (min-width: 769px) {
    .countDownModal {
        flex-direction: row;
        height: 600px;
        justify-content: flex-end;
    }

    .countDownModal .countDownBox {
        height: 100%;
        padding: 210px 40px 0px 100px;
        padding-left: 10%;
        justify-content: start;
    }

    .countDownModal .countDownBoxFalse {
        padding-left: 5%;
        padding-top: 150px;
    }

    .countDownTitle {
        font-size: 50px;
        line-height: 64px;
        margin-bottom: 20px;
    }

    .countDownContent {
        font-size: 18px;
        line-height: 28px;
        margin-bottom: 40px;
    }

    .countDownContentFalse {
        padding-right: 30%;
    }
}

@media screen and (min-width: 1025px) {
    .countDownModal .countDownBox {
        padding: 115px 60px 0px 100px;
        padding-left: 50%;
    }

    .countDownModal .countDownBoxFalse {
        padding-left: 10%;
    }

    .countDownContentFalse {
        padding-right: 48%;
    }
}

@media screen and (min-width: 1441px) {
    .countDownModal .countDownBox {
        padding: 130px 150px 0px 100px;
        padding-left: 45%;
    }

    .countDownModal .countDownBoxFalse {
        padding-left: 8%;
    }

    .countDownContentFalse {
        padding-right: 58%;
    }
}
.informationModal {
    display: flex;
    flex-direction: column;
}

.informationButtonM {
    display: flex;
    text-decoration: none;
}

.informationButtonL {
    display: none;
    text-decoration: none;
}

.informationModal .informationBox {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: end;
    padding: 40px;
    padding-top: 186px;
}

.informationModal .informationBoxFalse {
    padding-top: 40px;
}

.informationTitle {
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;
    margin-bottom: 10px;
    white-space: normal;
}

.informationContent {
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 24px;
    margin-bottom: 20px;
    white-space: normal;
}

.informationButton {
    font-size: 14px;
}

@media screen and (min-width: 471px) {

    .informationTitle {
        font-size: 30px;
        line-height: 44px;
        margin-bottom: 20px;
    }

    .informationContent {
        font-size: 18px;
        line-height: 28px;
        margin-bottom: 40px;
    }
    
    .informationButtonM {
        display: none;
    }

    .informationButtonL {
        display: flex;
    }
}

@media screen and (min-width: 642px) and (max-width: 769px) {
    .informationModal .informationBox {
        padding: 200px 172px 40px 40px;
    }

    .informationModal .informationBoxFalse {
        padding-Top: 50px;
    }
}


@media screen and (min-width: 769px) {
    .informationModal {
        flex-direction: row;
        height: 600px;
        justify-content: flex-end;
    }

    .informationModal .informationBox {
        height: 100%;
        padding: 210px 40px 0px 100px;
        padding-left: 10%;
        justify-content: start;
    }

    .informationModal .informationBoxFalse {
        padding-left: 5%;
        padding-top: 150px;
    }

    .informationTitle {
        font-size: 50px;
        line-height: 64px;
        margin-bottom: 20px;
    }

    .informationContent {
        font-size: 18px;
        line-height: 28px;
        margin-bottom: 40px;
    }

    .informationContentFalse {
        padding-right: 30%;
    }
}

@media screen and (min-width: 1025px) {
    .informationModal .informationBox {
        padding: 115px 60px 0px 100px;
        padding-left: 50%;
    }

    .informationModal .informationBoxFalse {
        padding-left: 10%;
    }

    .informationContentFalse {
        padding-right: 48%;
    }
}

@media screen and (min-width: 1441px) {
    .informationModal .informationBox {
        padding: 130px 150px 0px 100px;
        padding-left: 45%;
    }

    .informationModal .informationBoxFalse {
        padding-left: 8%;
    }

    .informationContentFalse {
        padding-right: 58%;
    }
}
.taxCampaignHighlight {
    display: flex;
    flex-direction: column;
}

.taxCampaignHighlightButtonM {
    display: flex;
    text-decoration: none;
}

.taxCampaignHighlightButtonL {
    display: none;
    text-decoration: none;
}

.taxCampaignHighlight .taxCampaignHighlightText {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: end;
    padding: 40px;
    padding-top: 186px;
    grid-gap: 2rem;
    gap: 2rem;
    align-items: flex-start;
}

@media screen and (min-width: 642px) and (max-width: 769px) {
    .taxCampaignHighlight .taxCampaignHighlightText {
        padding: 200px 172px 40px 40px;
    }
}

@media screen and (min-width: 471px) {

    .taxCampaignHighlightButtonM {
        display: none;
    }

    .taxCampaignHighlightButtonL {
        display: flex;
    }
}

@media screen and (min-width: 769px) {
    .taxCampaignHighlight {
        flex-direction: row;
        height: 600px;
        justify-content: flex-end;
    }

    .countDownTitle {
        font-size: 50px;
        line-height: 64px;
        margin-bottom: 20px;
    }

    .taxCampaignHighlight .taxCampaignHighlightText {
        height: 100%;
        padding: 210px 40px 0px 100px;
        padding-left: 10%;
        justify-content: start;
    }
}

@media screen and (min-width: 1025px) {
    .taxCampaignHighlight .taxCampaignHighlightText {
        padding: 115px 60px 0px 100px;
        padding-left: 50%;
    }
}

@media screen and (min-width: 1441px) {
    .taxCampaignHighlight .taxCampaignHighlightText {
        padding: 130px 150px 0px 100px;
        padding-left: 45%;
    }
}
.hof {
    display: flex;
    flex-direction: column;
}

.hofButtonM {
    display: flex;
    text-decoration: none;
}

.hofButtonL {
    display: none;
    text-decoration: none;
}

.hof .hofText {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: end;
    padding: 40px;
    padding-top: 186px;
    grid-gap: 2rem;
    gap: 2rem;
    align-items: flex-start;
}

@media screen and (min-width: 642px) and (max-width: 769px) {
    .hof .hofText {
        padding: 200px 172px 40px 40px;
    }
}

@media screen and (min-width: 471px) {

    .hofButtonM {
        display: none;
    }

    .hofButtonL {
        display: flex;
    }
}

@media screen and (min-width: 769px) {
    .hof {
        flex-direction: row;
        height: 600px;
        justify-content: flex-end;
    }

    .hofTitle {
        font-size: 50px;
        line-height: 64px;
        margin-bottom: 20px;
    }

    .hof .hofText {
        height: 100%;
        padding: 210px 40px 0px 100px;
        padding-left: 10%;
        justify-content: start;
    }
}

@media screen and (min-width: 1025px) {
    .hof .hofText {
        padding: 115px 60px 0px 100px;
        padding-left: 50%;
    }
}

@media screen and (min-width: 1441px) {
    .hof .hofText {
        padding: 130px 150px 0px 100px;
        padding-left: 45%;
    }
}
.cookie-popup-wrapper {
    left: 5vw;
    top: 10vh;
    position: fixed
}
@media screen and (min-width: 992px){ /* lg on bootstrap = 992 */
    .cookie-popup-wrapper {
        left: 100%;
        top: 0;
        position: absolute
    }
}
.have-you-seen-products, .featured-products {
    display: none;
}
.post-content{
    flex: 1 1;
}
@media screen and (min-width: 1024px) {
	.have-you-seen-products, .featured-products {
        display: flex;
    }
    .post-content{
        flex: 0.5 1;
    }
    .post-campaign-content{
        flex: 0.6 1;
    }
}








.shopBannerTitle, .shopBannerContent {
    padding-left: 10%;
    padding-right: 10%;
}
.shopBannerContent {
    /* max-width: 700px; */
    margin-top: 20px;
}
.shop-content{
    max-width: 100%;
    flex: 1 1
}
.product-grid{
    display: flex;
    flex-wrap: wrap;
}
.shop-content .product-grid .MuiGrid-item {
    display: flex;
    justify-content: center;
}
.resetFilterButton {
    justify-content: flex-start;
    margin-top: 20px !important;
}
.products-on-discount{
    display: none;
}
.play_pause_div{
    display:flex;
    width:100%;
    align-items:center;
    justify-content:center;
    position:absolute;
    left:0;
    right:0;
    top:80px;
    color:white;
}
.ThemeONEplay_pause_button_div{
    height:650px;
}
.ThemeTWOplay_pause_button_div{
    height:550px;
}
.ThemeTHREEplay_pause_button_div{
    height:550px;
}
#ThemeONEshop_banner_text{
    bottom:13%
}
#ThemeTWOshop_banner_text{
    bottom:8%
}
#ThemeTHREEshop_banner_text{
    bottom:8%
}
#play_pause{
    opacity:0.7;
    height:10vw;
    width:10vw;
}
.play_pause_button_div>#play_pause:hover{
    opacity: 1;
}
@media screen and (min-width: 768px) {
    .shopBannerTitle, .shopBannerContent {
        padding-left: 100px;
        padding-right: 100px;
    }
    .resetFilterButton {
        justify-content: flex-end;
        margin-top: 0 !important
    }
}

@media screen and (min-width: 1024px) {
    .products-on-discount{
        display: flex;
    }
}


.blend{
    mix-blend-mode:difference;
}
.product-container {
    display: flex;
    flex-direction: column;
}

.product-details-container ,
.product-details-container-theme2 {
    display: flex;
    flex-direction: column;
}

.play_pause_button {
    height: 650px;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    color: white;
}

#play_pause {
    opacity: 0.7;
    height:10vw;
    width:10vw;
}

.play_pause_button>#play_pause:hover {
    opacity: 1;
}

.product-related {
    margin-top: 10px;
    margin-bottom: 10px;
}

.product-image-container .carousel-control-next , 
.product-image-container-theme2 .carousel-control-next {
    position: absolute;
    bottom: 5rem;
    top: auto;
    right: 5rem;
    left: auto;
    z-index: 2;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    mix-blend-mode: difference;
}

.product-image-container .carousel-control-prev , 
.product-image-container-theme2 .carousel-control-prev {
    position: absolute;
    bottom: 5rem;
    top: auto;
    right: 10rem;
    left: auto;
    z-index: 2;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    mix-blend-mode: difference;
}

.product-image-container .carousel-indicators , 
.product-image-container-theme2 .carousel-indicators {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin: 0;
    bottom: 5rem;
    left: 5rem;
    mix-blend-mode: difference;
    -webkit-transform: translateY(6px);
            transform: translateY(6px);
}

.product-image-container .carousel-indicators [data-bs-target] ,
.product-image-container-theme2 .carousel-indicators [data-bs-target] {
    width: 12px;
    height: 12px;
    border-radius: 50%;
}

.product-info-tabs-container .TabUnstyled-root:focus ,
.product-info-tabs-container-theme2 .TabUnstyled-root:focus  {
    outline: none;
}

.product-info-tabs-container .TabUnstyled-root {
    cursor: pointer;
    font-weight: bold;
    background-color: transparent;
    padding: 12px 16px;
    margin: 6px 6px;
    border: none;
    border-radius: 14px;
    display: flex;
    justify-content: center;
    flex: 0.35 1;
}

.product-info-tabs-container-theme2 .TabUnstyled-root {
    cursor: pointer;
    font-weight: bold;
    background-color: transparent;
    padding: 12px 16px;
    margin: 6px 0px;
    border: none;
    border-radius: 14px;
    display: flex;
    justify-content: center;
    flex: 0.35 1;
}

.product-info-tabs-header {
    display: flex;
    align-items: center;
    justify-content: center;
    align-content: space-between;
    margin-bottom: 20px;
}

.product-info-tabs-header-theme2 {
    display: flex;
    align-items: center;
    justify-content: left;
    margin-bottom: 20px;
}

.product-details-section {
    display: flex;
    flex-direction: column;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-bottom: 2rem;
}


.size-variation,
.color-variation,
.title-variation {
    border-radius: 35px;
    padding: 5px;
    cursor: pointer;
    height: 35px;
    width: auto;
    min-width: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.basket_buttons{
    width:100%;
    grid-gap: 15px;
    gap: 15px;
}

.basket_buttons>button:nth-child(1){
    width:45%;
    justify-content: center;
}

.basket_buttons>button:nth-child(2){
    width:55%;
    justify-content: center;
}

.basket-card {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 480px;
    height: 305px;
    max-height: 305px;
    position: absolute;
    right: -480px;
    bottom: 40px;
    box-shadow: 0px 0px 100px rgba(34, 34, 34, 0.5);
    transition-duration: 1.5s;
    transition-property: right, opacity;
    transition-timing-function: linear;
    overflow: hidden;
    opacity: 0;
}

.basket-card.show {
    opacity: 1;
    overflow: scroll;
    width: 100%;
    right: 20px;
    max-width: 480px;
    transition-duration: 1.5s;
    transition-property: right, opacity;
    transition-timing-function: linear;
}

@media (min-width: 560px) {
    .product-details-section {
        padding-left: 5rem;
        padding-right: 5rem;
        padding-bottom: 5rem;
    }
}

@media (min-width: 1920px) {
    .product-image-container {
        flex: 0.4 1;
    }
    .product-image-container-theme2 {
        flex:0.52 1
    }

    .product-details-container {
        flex: 0.6 1;
    }

    .product-details-container-theme2 {
        flex:0.48 1;
    }

    .product-details-image {
        height: 1113px;
    }
}

@media (max-width: 1920px) {
    .product-image-container {
        flex: 0.4 1;
    }

    .product-image-container-theme2{
        flex:0.52 1;
    }

    .product-details-container {
        flex: 0.6 1;
    }

    .product-details-container-theme2 {
        flex:0.48 1;
    }

    .product-details-image {
        /* height: 1113px; */
        height: calc(40vw * 1.4);
    }
}

@media (max-width: 1800px) {
    .product-image-container {
        flex: 0.4 1;
    }

    .product-image-container-theme2 {
        flex:0.52 1
    }

    .product-details-container {
        flex: 0.6 1;
    }

    .product-details-container-theme2 {
        flex:0.48 1;
    }

    .product-details-image {
        /* height: 1044px; */
        height: calc(40vw * 1.4);
    }
}

@media (max-width: 1600px) {
    .product-image-container {
        flex: 0.4 1;
    }

    .product-image-container-theme2 {
        flex:0.52 1;
    }
    .product-details-container {
        flex: 0.6 1;
    }

    .product-details-container-theme2 {
        flex:0.48 1;
    }

    .product-details-image {
        /* height: 930px; */
        height: calc(40vw * 1.4);
    }
}

@media (max-width: 1400px) {
    .product-image-container {
        flex: 0.4 1;
    }

    .product-image-container-theme2{
        flex:0.52 1;
    }

    .product-details-container {
        flex: 0.6 1;
    }

    .product-details-container-theme2 {
        flex:0.48 1;
    }

    .product-details-image {
        /* height: 814px; */
        height: calc(40vw * 1.4);
    }
}

@media (max-width: 1200px) {
    .product-image-container {
        flex: 0.4 1;
    }

    .product-image-container-theme2 {
        flex:0.52 1;
    }

    .product-details-container {
        flex: 0.6 1;
    }

    .product-details-container-theme2 {
        flex:0.48 1;
    }

    .product-details-image {
        /* height: 698px; */
        height: calc(40vw * 1.4);
    }
}

@media (max-width: 1023px) {
    .product-image-container {
        flex: 0.4 1;
    }

    .product-image-container-theme2 {
        flex:0.52 1;
        order:10;
    }

    .product-details-container {
        flex: 0.6 1;
    }

    .product-details-container-theme2 {
        flex:0.48 1;
        order:20;
    }

    .product-details-image {
        height: calc(100vw * 1.28);
        /* background-size: contain !important; */
    }
}

@media (max-width: 768px) {
    .product-image-container {
        flex: 0.4 1;
    }

    .product-image-container-theme2 {
        flex:0.52 1
    }

    .product-details-container {
        flex: 0.6 1;
    }

    .product-details-container-theme2 {
        flex:0.48 1;
    }

    .product-details-image {
        height: calc(100vw * 1.28);
    }
}

@media (max-width: 520px) {
    .basket-card.show {
        width: 100%;
        right: 2.5%;
        max-width: 95%;
    }
}

@media (max-width: 480px) {
    .product-image-container {
        flex: 0.4 1;
    }

    .product-image-container-theme2 {
        flex:0.52 1;
    }

    .product-details-container {
        flex: 0.6 1;
    }

    .product-details-container-theme2 {
        flex:0.48 1;
    }

    .product-details-image {
        height: calc(100vw * 1.28);
    }
}

@media (min-width: 1024px) {
    .product-container {
        flex-direction: row;
    }

    .product-details-section {
        padding-top: 118px;
        padding-left: 100px;
        padding-right: 50px;
        padding-bottom: 50px;
    }

    .products-on-scroll {
        display: flex;
    }
}

[data-tooltip] {
    position: relative;
}

[data-tooltip]::before {
    content: attr(data-tooltip);
    position: absolute;
    bottom: -165%;
    z-index: 99;
    visibility: hidden;
    opacity: 0;
    background: #fff;
    border-radius: 25px;
    padding: 10px 18px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}

[data-tooltip]:hover::before {
    visibility: visible;
    opacity: 1;
    transition: opacity 0.5s;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -webkit-appearance: textfield;
          appearance: textfield;
}

.profileBannerTitle {
  padding-left: 10%;
  padding-right: 10%;
}
#graph-container{
  height:700px;
}
.goal-container{
  display:flex;
  flex-direction:row;
  justify-content:center;
  margin-top:-400px;
  grid-gap:40px;
  gap:40px;
}
.div-one{
  width:43%;
  /* flex:1; */
  height:220px;
  z-index: 1;
  box-shadow: 0px 5px 30px rgba(34, 34, 34, 0.15);
}
.personal-record-box{
  padding:30px 30px 0px 30px;
  width:100%;
  height:180px;
  background-color: #FFFFFF;
}
.div-two{
  width:43%;
  /* flex:1; */
  height:220px;
  z-index: 1;
  box-shadow: 0px 5px 30px rgba(34, 34, 34, 0.15);
}
.goal-box{
  padding:30px 30px 0px 30px;
  width:100%;
  height:180px;
  background-color: #FFFFFF;
  z-index: 1;
}
.pointsGridOne {
  height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px 35px;
}
.pointsGridTwo {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px 35px;
  height: 100px;
  border: 1px solid white;
}
.gridCol {
  padding-right: 0px !important;
  padding-left: 0px !important;
}
.ProfileProductBanner {
  padding: 10px;
}
.WishlistGrid {
  padding: 2px;
}
.text {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  line-clamp: 3;
  -webkit-box-orient: vertical;
}
.singleWishlistWrapper {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  box-shadow: 0px 0px 100px rgba(34, 34, 34, 0.15);
  padding: 20px;
}
.singleWishListText {
  width: 100%;
  height: 453px;
  display: flex;
  flex-direction: column;
  grid-gap: 1rem;
  gap: 1rem;
  justify-content: flex-start;
}
.singleWishlistImage {
  width: 100%;
  height: 350px;
}
.productsLimited3 {
  display: flex;
  position: relative;
  flex-direction: column;
  grid-gap: 1rem;
  gap: 1rem;
  justify-content: flex-start;
}
.lableTagButon {
  margin-top: 4px;
  margin-left: 3px;
}
.outOfStock {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  grid-gap: 1rem;
  gap: 1rem;
  position: absolute;
  z-index: 2;
  background: rgba(34, 34, 34, 0.5);
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
.point-col-lg {
  flex: 0 0 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 15px;
}
.graph-block {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 0;
  width: 125%;
  -webkit-transform: translateX(-10%);
          transform: translateX(-10%);
}
.settingInputbox {
  flex-direction: column;
}
.menuBox {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  width: 100%;
  margin-top: 150px;
  margin-bottom: 100px;
}
.menuButton {
  font-weight: bold;
  cursor: pointer;
  border-bottom: 1px solid rgb(191,191, 191);
  padding: 20px;
}
.menuButton:hover {
  border-bottom: 1px solid rgb(229, 107, 46);
  color: rgb(191,191, 191);
}
.menuTop {
  display: none;
}
.orderHead {
  flex-direction: column;
  grid-gap: 10px;
  gap: 10px;
  margin-bottom: 30px;
}
.countDownHorizontalItems > .horizontalScrollBlock > .grid-container > .main > .items > .item {
  width: 100%;
}

@media screen and (min-width: 500px) {
  .orderHead {
    flex-direction: row;
    margin-bottom: 0;
  }
}

@media screen and (max-width:620px) {
  #graph-container{
    height:880px;
  }
  .goal-container{
    flex-direction:column;
    margin-top:-605px;
    grid-gap:0px;
    gap:0px;
  }
  .div-one{
    width:75%;
    height: -webkit-max-content;
    height: max-content;
    margin:auto;
  }
  .div-two{
    width:75%;
    height: -webkit-max-content;
    height: max-content;
    margin:auto;
    margin-top: 20px;
  }
  .personal-record-box{
    width:100%;
    margin:auto;
    height:-webkit-max-content;
    height:max-content;
  }
  .goal-box{
    width:100%;
    margin:auto;
    height: -webkit-max-content;
    height: max-content;
  }
  .received-points{
    padding-bottom:34px;
  }
}

@media screen and (min-width: 768px) {
  .menuBox {
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 1rem;
    -webkit-column-gap: 1rem;
            column-gap: 1rem;
  }
  .profileBannerTitle {
    padding-left: 100px;
    padding-right: 100px;
  }
  .singleWishlistWrapper {
    flex-direction: row;
  }
  .singleWishListText {
    width: 40%;
    justify-content: center;
  }
  .singleWishlistImage {
    width: 50%;
    height: 453px;
  }
  .productsLimited3 {
    flex-direction: row;
  }
  .lableTagButon {
    margin-left: 190px;
  }
  .point-col-lg {
    flex: 0 0 50%;
  }
  .settingInputbox {
    flex-direction: row;
  }
}

@media screen and (min-width:620px) and (max-width:1280px) {
  #graph-container{
    height:820px;
  }
  .goal-container{
    flex-direction:column;
    margin-top:-520px;
    grid-gap:0px;
    gap:0px;
  }
  .div-one{
    width:85%;
    height:220px;
    margin:auto;
  }
  .div-two{
    width:85%;
    height:220px;
    margin:auto;
    margin-top: 15px;
  }
  .personal-record-box{
    width:100%;
    margin:auto;
    height:180px;
  }
  .goal-box{
    width:100%;
    margin:auto;
    height:180px;
  }
}

@media screen and (min-width: 1024px) {
  .ProfileProductBanner {
    padding: 80px;
  }
  .WishlistGrid {
    padding: 8px;
  }
  .point-col-lg {
    flex: 0 0 25%;
  }
  .graph-block {
    width: calc(100% + 160px);
    -webkit-transform: translateX(-80px);
            transform: translateX(-80px);
  }
}

@media screen and (min-width: 1200px) {
  .menuBox {
    display: none;
  }
  .menuTop {
    display: inline-block;
  }
}

@media screen and (min-width: 1280px) {
  .graph-block {
    width: calc(100% + 200px);
    -webkit-transform: translateX(-100px);
            transform: translateX(-100px);
  }
}

/* Activation Frid Section */

.activationSectionGrid {
  display: flex;
  flex-direction: column;
  grid-gap: 10px;
  gap: 10px;
  margin-top: -100px;
}

.gridSection1 {
  height: 250px;
  width: 100%;
  flex: 1 1;
  display: flex;
  flex-direction: column;
  grid-gap: 10px;
  gap: 10px;
  margin-bottom: 50px;
}

.gridSection2 {
  width: 100%;
  display: flex;
  flex-direction: column;
  grid-gap: 10px;
  gap: 10px;
}

.gridSection1Box {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  padding: 20px;
  grid-gap: 10px;
  gap: 10px;
}

.gridSection2Box {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 25px;
  grid-gap: 20px;
  gap: 20px;
}

.gridSection2Box:nth-child(1)  div:nth-child(1) {
  display: flex;
  flex-direction: column;
  align-items: center;
  grid-gap: 10px;
  gap: 10px;
}

.gridSection2Box:nth-child(2)  div:nth-child(1) {
  display: flex;
  flex-direction: column;
  align-items: center;
  grid-gap: 10px;
  gap: 10px;
}

.arrowActivation {
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
}

.activationPragraphText {
  text-align: center;
}

.calculationDiv {
  display: flex;
  flex-direction: column;
  align-items: center;
  grid-gap: 10px;
  gap: 10px;
}

@media screen and (min-Width: 1024px) {

  .gridSection1 {
    height: 240px;
    flex-direction: row;
  }

  .gridSection2Box {
    flex-direction: row;
    grid-gap: 30px;
    gap: 30px;
    align-items: center;
  }

  .gridSection2Box:nth-child(1)  div:nth-child(1) {
    width: 360px;
    flex-direction: row;
  }

  .gridSection2Box:nth-child(2)  div:nth-child(1) {
    width: 360px;
    flex-direction: row;
  }

  .gridSection2Box:nth-child(3) {
    flex-direction: column;
  }

  .gridSection2Box:nth-child(3) .arrowActivation {
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
  }

  .gridSection2Box:nth-child(3) .activationPragraphText {
    text-align: center;
  }

  .arrowActivation {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }

  .activationPragraphText {
    text-align: left;
  }

  .calculationDiv {
    flex-direction: row;
  }
}

@media screen and (min-Width: 1301px) {
  
  .gridSection2Box:nth-child(3) {
    flex-direction: row;
  }

  .gridSection2Box:nth-child(1)  div:nth-child(1) {
    width: 370px;
  }

  .gridSection2Box:nth-child(2)  div:nth-child(1) {
    width: 370px;
  }
  
  .gridSection2Box:nth-child(3) .arrowActivation {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }

  .gridSection2Box:nth-child(3) .activationPragraphText {
    text-align: left;
  }
}

@media screen and (min-Width: 1200px) {
  .activationSectionGrid {
    margin-top: 50px;
  }
}

/* Activation Frid Section END */



.profileCorrectStatementInfoText {
  max-width: 100%;
}


@media screen and (min-Width: 1200px) {
  .profileCorrectStatementInfoText {
    max-width: 72%;
  }
}
#orders-block{
    border-radius: 14px;
    overflow: hidden;
}
.order-summary {
    display: flex;
    flex: 1 1;
    grid-gap: 3rem;
    gap: 3rem;
    padding: 30px;
    flex-direction: column
}
.order-summary-one{
    display: flex;
    flex: 1 1;
    justify-content: space-between;
    grid-gap: 1rem;
    gap: 1rem;
    flex-direction: column;
}
.order-summary-two{
    display: flex;
    flex: 1 1;
    justify-content: space-around;
    grid-gap: 1rem;
    gap: 1rem;
    flex-direction: column;
}
.order-number, .order-status, .order-date, .order-track {
    display: flex;
    flex-direction: column;
    grid-gap: 10px;
    gap: 10px;
}

.order-items-container{
    display: flex;
    flex: 1 1;
    justify-content: space-between;
    grid-gap: 2rem;
    gap: 2rem;
    flex-direction: column-reverse;
}
.order-items-address{
    display: flex;
    flex-direction: column;
    grid-gap: 10px;
    gap: 10px;
    width: 100%;
}
.order-item-container{
    display: flex;
    cursor: pointer;
    grid-gap: 2rem;
    gap: 2rem;
}
.order-item-image{
    height: 293px;
    width: 100%;
    border-width: 0.5px;
    border-style: solid;
}
.order-items {
    display: flex;
    flex-direction: column;
    flex: 1 1;
    width: 100%;
}
.order-item-wrapper{
    padding: 40px 0;
    display: flex;
    flex: 1 1;
    width: 100%;
    max-width: 650px;
    grid-gap: 2.5rem;
    gap: 2.5rem;
    flex-direction: column;
    border-width: 0 0 1px 0;
    border-style: solid;
}
.order-item-info{
    display: flex;
    flex-direction: column;
    grid-gap: 10px;
    gap: 10px;
    justify-content: space-between;
}
.order-item-details{
    display: flex;
    flex-direction: row;
    flex: 1 1;
    justify-content: space-between;
}

.order-items{
    display: flex;
    flex-direction: column;
    padding: 40px;
}

@media (min-width: 550px) {
    .order-item-image{
        height: 175px;
        width: 140px;
    }
    .order-item-wrapper{
        flex-direction: row;
    }
    .order-summary-one{
        display: flex;
        flex: 1 1;
        justify-content: space-between;
        grid-gap: 1rem;
        gap: 1rem;
        flex-direction: column;
    }
    .order-summary-two{
        display: flex;
        flex: 1 1;
        justify-content: space-around;
        grid-gap: 1rem;
        gap: 1rem;
        flex-direction: column-reverse;
    }
    .order-summary{
        flex-direction: row;
    }
}
@media (min-width: 1024px) {
    .order-summary-one{
        display: flex;
        flex: 1 1;
        justify-content: space-between;
        grid-gap: 1rem;
        gap: 1rem;
        flex-direction: row
    }
    .order-summary-two{
        display: flex;
        flex: 1 1;
        justify-content: space-around;
        grid-gap: 1rem;
        gap: 1rem;
        flex-direction: row
    }
    .order-items{
        padding: 0;
    }
    .order-items-address{
        max-width: 327px;
    }
    .order-items-container{
        flex-direction: row;
        padding: 40px;
    }
}

#orders-block.MuiAccordion-root{
    box-shadow: 0px 0px 1px 1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
}

#message-block{
    border-radius: 14px;
    overflow: hidden;
}

.message-summary {
    display: flex;
    grid-gap: 3rem;
    gap: 3rem;
    padding: 30px;
    flex-direction: column
}

.message-summary-one {
    display: flex;
    flex-direction: column;
    grid-gap: 5px;
    gap: 5px;
}

.message-detail-container {
    display: flex;
    flex: 1 1;
    justify-content: space-between;
    grid-gap: 1.5rem;
    gap: 1.5rem;
    padding: 30px;
    flex-direction: column;
}

#message-block.MuiAccordion-root{
    box-shadow: 0px 0px 1px 1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
}
.progress {
    overflow: visible;
    width: 100%;
    height: 100%;
}
.progress-bar {
    z-index: 0;
    position: relative;
    overflow: visible;
}
.progress-bar span {
    background-color: #ffffff;
    position: absolute;
    bottom: 34px;
    font-size: 10px;
    line-height: 10px;
    padding: 10px 15px 10px 15px;
    right: -50px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    color: black;
}
.progress-bar span:after {
    border-left: solid transparent 10px;
    border-right: solid transparent 10px;
    border-top: solid #ffffff 10px;
    bottom: -10px;
    content: " ";
    height: 0;
    left: 50px;
    margin-left: -12px;
    position: absolute;
    width: 0;
}
.progress-bar p {
    margin-bottom: 5px;
    color: #8C8C8C;
    font-weight: 400;
    font-size: 12px;
}
.progress-bar h6 {
    width: 60px;
    margin: 0;
    font-style: normal;
    font-family:Arial, Helvetica, sans-serif;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
}
.min-max-value{
    margin-top: -33px;
    height: 40px;
    width: 100%;
    display:flex;
    justify-content: space-between;
    padding: 0px 20px 0px 20px;
    font-style: normal;
    font-family:Arial, Helvetica, sans-serif;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
}
.current_points {
    overflow:visible ;
    color: white;
    z-index: 1;
}
.max_points {
    z-index: 1;
    color: white;
}
.transaction-table {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-width: 700px;
}

.transaction-table .table-head,
.transaction-table .table-body .tr
 {
  display: flex;
  flex-direction: row;
  padding: 15px;
}

.tcol-5 {
  flex: 0 0 41.66667%;
}

.tcol-4 {
  flex: 0 0 24.66667%;
}

.tcol-1 {
  flex: 0 0 9.66667%;
}

.tcol-2 {
  flex: 0 0 17%;
}

.tcol-3 {
  flex: 0 0 25%;
  text-align: right;
}

.transaction-table .table-body {
  max-height: 35.5em;
  overflow: auto;
}

.transaction-table .table-body .tr {
  background-color: #f2f2f2;
  font-size: 0.9em;
  vertical-align: middle;
}

.transaction-table .table-head .th {
  font-weight: bold;
}

.transaction-table .table-body .tr:nth-child(even) {
  background-color: white
}

.save_sendpoint:disabled{
    background-color:#d1d1d1;
    color:#a6a6a6;
    border-radius: 5px;
}
.faq-section {
    display: flex;
    flex-direction: column;
    margin-top: 100px;
    justify-content: space-between;
    grid-gap: 30px;
    gap: 30px
}

.faq-list {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.faq-title-item {
    display: flex;
    min-height: 80px;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
}

.faq-details-section{
    display: flex;
    flex-direction: column;
    margin-top: 100px;
}
.faq-close-icon{
    display: none;
}

.faq-content{
    display: flex;
    flex-direction: column;
    grid-gap: 2rem;
    gap: 2rem;
    width: 100%;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    overflow: scroll;
    padding: 4rem;
    background: white;
    z-index: 2;
}
.faq-modal-hide{
    display: none;
}

.faq-close-icon{
    display: flex;
    align-self: flex-end;
    cursor: pointer;
}

.faq-content-div{
    display:flex;
    flex-direction:row;
    grid-gap:36px;
    gap:36px;
}

.like-dislike-div{
    grid-gap:100px;
    gap:100px;
}

.like-button{
background: #FFFFFF;
border: 1px solid #BFBFBF;
border-radius: 50px;
height:48px;
width:48px;
margin-bottom: 15px;
}

.dislike-button{
background: #FFFFFF;
border: 1px solid #BFBFBF;
border-radius: 50px;
height:48px;
width:48px;
}

@media screen and (min-width: 1024px) {
    .faq-section {
        flex-direction: row;
    }
    .faq-list {
        width: 30%;
    }
    .faq-close-icon{
        display: flex;
    }
    .faq-content{
        display: flex;
        flex-direction: column;
        grid-gap: 2rem;
        gap: 2rem;
        width: 60%;
        position: static;
        padding: 0;
        background: inherit;
        z-index: inherit;
    }
    .faq-details-section {
        flex-direction: row;
        justify-content: space-between;
    }
    .faq-modal-hide{
        display: flex;
    }
    .faq-close-icon{
        display: none;
    }
}

.faq-contact-us-title{
    width: 708.42px;
    height: 74px;
}

.faq-contact-content{
    max-width: 70%;
}

#faq-contact-flex-box{
    display:flex;
    grid-gap: 40px;
    gap: 40px;
}    

.faq-Contact-box{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 60px;
    grid-gap: 40px;
    gap: 40px;
    flex:1 1;
    min-height:380px;
}
    
.faq-Personal-shopper-box{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 60px;
    grid-gap: 20px;
    gap: 20px;
    color: #FFFFFF;
    flex:1 1;
    min-height:380px;
}
    
.faq-contact-list{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
}

@media screen and (max-width: 1000px) {

    .faq-contact-content{
        max-width: 100%;
    }
     
    #faq-contact-flex-box{
        display:flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .faq-Contact-box{
        align-items: flex-start;
        padding: 46px;
        grid-gap: 40px;
        gap: 40px;
        width: 100%;
        min-height: 360px;
    }

    .faq-Personal-shopper-box{
        align-items: flex-start;
        padding: 46px;
        grid-gap: 20px;
        gap: 20px;
        width: 100%;
        min-height: 360px;
    }

}

@media screen and (max-width: 500px)  {
    .faq-content-div{
        display:flex;
        flex-direction:column;
        grid-gap:36px;
        gap:36px;
        align-items: center;
    }

    .like-dislike-div{
        display:flex;
        grid-gap:0px;
        gap:0px;
        width:110px;
        justify-content: space-between;
    }

    .faq-Contact-box{
        align-items: flex-start;
        padding: 30px;
        grid-gap: 40px;
        gap: 40px;
        width: 100%;
        min-height: 360px;
    }
    
}
.no-results-text{
    text-align: center;
    font-size: 22px;
    font-weight: bold;
    margin-top: 12px !important;
}
.w-100{
    width: 100% !important;
}

.searchButtonGroup{
    margin-bottom: 10px;
    padding: 1rem;
}
.mt-75 {
  margin-top: 75px;
}

.termsOfTrade-title {
  font-size: 50px !important;
}
@media screen and (max-width: 450px) {
  .termsOfTrade-title {
    font-size: 25px !important;
  }
}
.mt-15{
    margin-top: 15px !important;
}

.mt-40{
    margin-top: 40px !important;
}
.mb-55{
    margin-bottom: 55px !important;
}

.personalShopperDetails {
    width: 100%;
}

@media screen and (min-width: 1280px) {
    .personalShopperDetails {
        width: 60%;
    }
}

@media screen and (min-width: 1440px) {
    .personalShopperDetails {
        width: 50%;
    }
}

.writeToUs {
    padding: 100px 30px 100px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: start;
    grid-gap: 100px;
    gap: 100px;
}

@media screen and (min-width: 768px) {
    .writeToUs {
        padding: 120px 60px 64px;
    }
}

@media screen and (min-width: 1289px) {
    .writeToUs {
        flex-direction: row;
    }
}

@media screen and (min-width: 1024px) {
    .writeToUs {
        padding: 120px 105px 120px;
    }
}

.writeToUsNote {
    width: 100%;
    margin-bottom: 51px;
}

@media screen and (min-width: 768px) {
    .writeToUsNote {
        width: 60%;
        margin-bottom: 71px;
    }
}

@media screen and (min-width: 1440px) {
    .writeToUsForm {
        width: 60%;
        max-width: 600px;
    }
}

@media screen and (min-width: 1024px) {
    .writeToUsNote {
        width: 100%;
        margin-bottom: 43px;
    }
}

.wantaProduct {
    margin-top: 1015px;
}

@media screen and (min-width: 768px) {
    .wantaProduct {
        margin-top: 772px;
    }
}

@media screen and (min-width: 1024px) {
    .wantaProduct {
        margin-top: 800px;
    }
}

.personal-shopper-wrapper{
    display: flex;
    flex-direction: row;
    flex: 1 1;
    grid-gap: 1rem;
    gap: 1rem;
    justify-content: space-between;
}
.personal-shopper-wrapper > div {
    width: 45%;
}


@media screen and (max-width: 1024px) {
    .personal-shopper-wrapper{
        flex-direction: column;
    }
    .personal-shopper-wrapper > div {
        width: 100%;
    }
}
.ThemeONEcompetition_wrapper {
    position: absolute;
    width:100%;
    left:0;
    right:0;
    top:80px;
}
.ThemeTWOcompetition_wrapper {
    position: absolute;
    width:100%;
    left:0;
    right:0;
    top:0;
}
.ThemeTHREEcompetition_wrapper {
    position: absolute;
    width:100%;
    left:0;
    right:0;
    top:0px;
}
.ThemeFOURcompetition_wrapper {
    position: absolute;
    width:100%;
    left:0;
    right:0;
    top:80px;
}
.ThemeONEcompetition_title{
    margin-top: 780px;
}
.ThemeTWOcompetition_title {
    margin-top: 700px;
}
.ThemeTHREEcompetition_title {
    margin-top: 700px;
}
.ThemeFOURcompetition_title{
    margin-top: 780px;
}
.competition_title {
    padding-left: 10%;
    padding-right: 10%;
}

.prize-products {
    display: none;
}


.competition-content{
    flex: 1 1;
}

@media screen and (min-width: 1280px) {
    .post-title {
        padding-left: 100px;
        padding-right: 100px;
    }
}

@media screen and (min-width: 1024px) {
	.prize-products {
        display: flex;
    }

    .competition-content{
        flex: 0.5 1;
    }
}
.register_div {
    margin: auto;
    padding: 20px;
    max-width: 500px;
}

.register_headline {
    font-weight: 400;
    margin: auto;
    justify-content: center;
}

.description_message {
    display: block;
    text-align: left;
    font-weight: 600;
    margin-bottom: 3%;
    font-weight: 500;
    line-height: 1.2;
    font-size: 1rem;
}

.register_instruction {
    margin-bottom: 3%;
    font-weight: 600;
    line-height: 1.3;
}

/* register Form */

.label_style {
    display: inline-block;
    margin-bottom: 0.5rem;
    margin-top: 1rem;
}

.register_div .form-group {
    text-align: left;
    width: 100%;
}

.register_div .form-control {
    width: 100%;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
    .register_div .form-control {
        transition: none
    }
}

.register_div .form-control::-ms-expand {
    background-color: transparent;
    border: 0
}

.register_div .form-control:focus {
    color: #495057;
    background-color: #fff;
    border-color: #80bdff;
    outline: 0;
    box-shadow: 0 0 0 .2rem rgba(0, 123, 255, 0.25)
}

.register_div .form-control::-webkit-input-placeholder {
    color: #6c757d;
    opacity: 1
}

.register_div .form-control:-ms-input-placeholder {
    color: #6c757d;
    opacity: 1
}

.register_div .form-control::placeholder {
    color: #6c757d;
    opacity: 1
}

.register_div .form-control:disabled,
.register_div .form-control[readonly] {
    background-color: #e9ecef;
    opacity: 1
}

.register_div select.form-control:focus::-ms-value {
    color: #495057;
    background-color: #fff
}

.register_div .form-control-file,
.register_div .form-control-range {
    display: block;
    width: 100%
}

.selector_div {
    width: 105%;
}

.mobile_controller {
    display: flex;
}

.country_code {
    display: block;
    width: 55px;
}

.mobile_input {
    display: block;
    width: 100%;
    margin-left: 1rem;
}

.postal_div {
    display: block;
    width: 120px;
}

.address_div {
    display: block;
    width: 100%;
    margin-left: 1rem;
}

.register_div .custom-control-input {
    position: relative;
    display: inline-block;
    min-height: 1.5rem;
    padding-left: 1.5rem;
    margin-top: 1rem;
}

.terms_condition {
    display: block;
    margin-top: 1rem;
    margin-left: 2rem;
    font-weight: 400;
    font-size: 1rem;
}

.black_button_div {
    display: flex;
    justify-content: center;
    margin: 2rem;
    margin-bottom: 4rem;
}

.standardBtn_black {
    max-width: 250px;
    font-weight: bold;
    background-color: #111111;
    min-height: 50px;
    border: 0px solid transparent;
    width: 100%;
    color: #fff;
    text-align: center;
    font-size: 1rem;
    border-radius: 100px;
}

.btn:not(:disabled):not(.disabled) {
    cursor: pointer;
}

.is_invalid {
    padding-right: calc(1.5em + 0.75rem);
    background-image: url('https://img.icons8.com/emoji/48/null/cross-mark-emoji.png');
    background-repeat: no-repeat;
    background-position: center right calc(0.375em + 0.1875rem);
    background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
    width: 100%;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    border-color: #dc3545;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.ThemeONEchristmas_calendar_wrapper {
    position: absolute;
    width:100%;
    left:0;
    right:0;
    top:80px;
}
.ThemeTWOchristmas_calendar_wrapper {
    position: absolute;
    width:100%;
    left:0;
    right:0;
    top:0;
}
.ThemeTHREEchristmas_calendar_wrapper {
    position: absolute;
    width:100%;
    left:0;
    right:0;
    top:0px;
}
.ThemeFOURchristmas_calendar_wrapper {
    position: absolute;
    width:100%;
    left:0;
    right:0;
    top:80px;
}
.ThemeONEchristmas_calendar_title{
    margin-top: 780px;
}
.ThemeTWOchristmas_calendar_title {
    margin-top: 700px;
}
.ThemeTHREEchristmas_calendar_title {
    margin-top: 700px;
}
.ThemeFOURchristmas_calendar_title {
    margin-top: 780px;
}
.christmas_calendar_title {
    padding-left: 10%;
    padding-right: 10%;
}

.christmas_calendar-content{
    flex: 1 1;
}

@media screen and (min-width: 1024px) {
	.christmas_calendar-content{
        flex: 0.5 1;
    }
}

.tooltip-content {
    padding: 15px;
    background-color: white;
    position: absolute;
    border-radius: 8px;
    z-index: 10;
    /* max-width: 550px; */
    display: flex;
    flex-direction: column;
    transition: opacity 2s;
    opacity: 1;
    box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
}

@media screen and (min-width: 768px) {
    .tooltip-content {
        max-width: 550px;
    }
}

.tooltip {
    padding: 10px;
    width: 28px;
    height: 28px;
}

.tooltip-text {
    font-size: 14px;
}

@media screen and (min-width: 426px) {
	.tooltip {
        padding: 10px;
        width: 30px;
        height: 30px;
    }

    .tooltip-text {
        font-size: 17px;
    }

}

@media screen and (min-width: 600px) {
	.tooltip {
        padding: 10px;
        width: 35px;
        height: 35px;
    }

    .tooltip-text {
        font-size: 19px;
    }

}

@media screen and (min-width: 768px) {
	.tooltip {
        padding: 15px;
        width: 50px;
        height: 50px;
    }

    .tooltip-text {
        font-size: 28px;
    }
}

@media screen and (min-width: 1024px) {
	.tooltip {
        padding: 20px;
        width: 70px;
        height: 70px;
    }

    .tooltip-text {
        font-size: 34px;
    }
}

@media screen and (min-width: 1439px) {
	.tooltip {
        padding: 25px;
        width: 80px;
        height: 80px;
    }

    .tooltip-text {
        font-size: 36px;
    }
}

@media screen and (min-width: 2560px) {
	.tooltip {
        padding: 30px;
        width: 85px;
        height: 85px;
    }

    .tooltip-text {
        font-size: 38px;
    }
}

.calendarImage {
    min-height: 700px;
}

@media screen and (max-width: 1024px) {
    .calendarImage {
        aspect-ratio: 1.5;
        min-height: 0px;
    }
}

.product-image{
    height: 293px;
    width: 100%;
    border-width: 0.5px;
    border-style: solid;
}

@media (min-width: 550px) {
    .product-image{
        height: 160px;
        width: 118px;
    }
}

.package-container {
    display: flex;
    flex-direction: column;
}

.package-details-container ,
.package-details-container-theme2 {
    display: flex;
    flex-direction: column;
}

.play_pause_button {
    height: 650px;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    color: white;
}

#play_pause {
    opacity: 0.7;
    height:10vw;
    width:10vw;
}

.play_pause_button>#play_pause:hover {
    opacity: 1;
}

.package-related {
    margin-top: 10px;
    margin-bottom: 10px;
}

.package-image-container .carousel-control-next , 
.package-image-container-theme2 .carousel-control-next {
    position: absolute;
    bottom: 5rem;
    top: auto;
    right: 5rem;
    left: auto;
    z-index: 2;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    mix-blend-mode: difference;
}

.package-image-container .carousel-control-prev , 
.package-image-container-theme2 .carousel-control-prev {
    position: absolute;
    bottom: 5rem;
    top: auto;
    right: 10rem;
    left: auto;
    z-index: 2;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    mix-blend-mode: difference;
}

.package-image-container .carousel-indicators , 
.package-image-container-theme2 .carousel-indicators {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin: 0;
    bottom: 5rem;
    left: 5rem;
    mix-blend-mode: difference;
    -webkit-transform: translateY(6px);
            transform: translateY(6px);
}

.package-image-container .carousel-indicators [data-bs-target] ,
.package-image-container-theme2 .carousel-indicators [data-bs-target] {
    width: 12px;
    height: 12px;
    border-radius: 50%;
}

.package-info-tabs-container .TabUnstyled-root:focus ,
.package-info-tabs-container-theme2 .TabUnstyled-root:focus  {
    outline: none;
}

.package-info-tabs-container .TabUnstyled-root {
    cursor: pointer;
    font-weight: bold;
    background-color: transparent;
    padding: 12px 16px;
    margin: 6px 6px;
    border: none;
    border-radius: 14px;
    display: flex;
    justify-content: center;
    flex: 0.35 1;
}

.package-info-tabs-container-theme2 .TabUnstyled-root {
    cursor: pointer;
    font-weight: bold;
    background-color: transparent;
    padding: 12px 16px;
    margin: 6px 0px;
    border: none;
    border-radius: 14px;
    display: flex;
    justify-content: center;
    flex: 0.35 1;
}

.package-info-tabs-header {
    display: flex;
    align-items: center;
    justify-content: center;
    align-content: space-between;
    margin-bottom: 20px;
}

.package-info-tabs-header-theme2 {
    display: flex;
    align-items: center;
    justify-content: left;
    margin-bottom: 20px;
}

.package-details-section {
    display: flex;
    flex-direction: column;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-bottom: 2rem;
}


.size-variation,
.color-variation,
.title-variation {
    border-radius: 35px;
    padding: 5px;
    cursor: pointer;
    height: 35px;
    width: auto;
    min-width: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.basket_buttons{
    width:100%;
    grid-gap: 15px;
    gap: 15px;
}

.basket_buttons>button:nth-child(1){
    width:45%;
    justify-content: center;
}

.basket_buttons>button:nth-child(2){
    width:55%;
    justify-content: center;
}

.basket-card-package {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 480px;
    position: absolute;
    right: -480px;
    bottom: 40px;
    box-shadow: 0px 0px 100px rgba(34, 34, 34, 0.5);
    transition-duration: 1.5s;
    transition-property: right, opacity;
    transition-timing-function: linear;
    opacity: 0;
}

.basket-card-package.show {
    opacity: 1;
    overflow: scroll;
    width: 100%;
    right: 20px;
    max-width: 480px;
    transition-duration: 1.5s;
    transition-property: right, opacity;
    transition-timing-function: linear;
}

@media (min-width: 560px) {
    .package-details-section {
        padding-left: 5rem;
        padding-right: 5rem;
        padding-bottom: 5rem;
    }
}

@media (min-width: 1920px) {
    .package-image-container {
        flex: 0.4 1;
    }
    .package-image-container-theme2 {
        flex:0.52 1
    }

    .package-details-container {
        flex: 0.6 1;
    }

    .package-details-container-theme2 {
        flex:0.48 1;
    }

    .package-details-image {
        height: 1113px;
    }
}

@media (max-width: 1920px) {
    .package-image-container {
        flex: 0.4 1;
    }

    .package-image-container-theme2{
        flex:0.52 1;
    }

    .package-details-container {
        flex: 0.6 1;
    }

    .package-details-container-theme2 {
        flex:0.48 1;
    }

    .package-details-image {
        /* height: 1113px; */
        height: calc(40vw * 1.4);
    }
}

@media (max-width: 1800px) {
    .package-image-container {
        flex: 0.4 1;
    }

    .package-image-container-theme2 {
        flex:0.52 1
    }

    .package-details-container {
        flex: 0.6 1;
    }

    .package-details-container-theme2 {
        flex:0.48 1;
    }

    .package-details-image {
        /* height: 1044px; */
        height: calc(40vw * 1.4);
    }
}

@media (max-width: 1600px) {
    .package-image-container {
        flex: 0.4 1;
    }

    .package-image-container-theme2 {
        flex:0.52 1;
    }
    .package-details-container {
        flex: 0.6 1;
    }

    .package-details-container-theme2 {
        flex:0.48 1;
    }

    .package-details-image {
        /* height: 930px; */
        height: calc(40vw * 1.4);
    }
}

@media (max-width: 1400px) {
    .package-image-container {
        flex: 0.4 1;
    }

    .package-image-container-theme2{
        flex:0.52 1;
    }

    .package-details-container {
        flex: 0.6 1;
    }

    .package-details-container-theme2 {
        flex:0.48 1;
    }

    .package-details-image {
        /* height: 814px; */
        height: calc(40vw * 1.4);
    }
}

@media (max-width: 1200px) {
    .package-image-container {
        flex: 0.4 1;
    }

    .package-image-container-theme2 {
        flex:0.52 1;
    }

    .package-details-container {
        flex: 0.6 1;
    }

    .package-details-container-theme2 {
        flex:0.48 1;
    }

    .package-details-image {
        /* height: 698px; */
        height: calc(40vw * 1.4);
    }
}

@media (max-width: 1023px) {
    .package-image-container {
        flex: 0.4 1;
    }

    .package-image-container-theme2 {
        flex:0.52 1;
        order:10;
    }

    .package-details-container {
        flex: 0.6 1;
    }

    .package-details-container-theme2 {
        flex:0.48 1;
        order:20;
    }

    .package-details-image {
        height: calc(100vw * 1.28);
        /* background-size: contain !important; */
    }
}

@media (max-width: 768px) {
    .package-image-container {
        flex: 0.4 1;
    }

    .package-image-container-theme2 {
        flex:0.52 1
    }

    .package-details-container {
        flex: 0.6 1;
    }

    .package-details-container-theme2 {
        flex:0.48 1;
    }

    .package-details-image {
        height: calc(100vw * 1.28);
    }
}

@media (max-width: 520px) {
    .basket-card-package.show {
        width: 100%;
        right: 2.5%;
        max-width: 95%;
    }
}

@media (max-width: 480px) {
    .package-image-container {
        flex: 0.4 1;
    }

    .package-image-container-theme2 {
        flex:0.52 1;
    }

    .package-details-container {
        flex: 0.6 1;
    }

    .package-details-container-theme2 {
        flex:0.48 1;
    }

    .package-details-image {
        height: calc(100vw * 1.28);
    }
}

@media (min-width: 1024px) {
    .package-container {
        flex-direction: row;
    }

    .package-details-section {
        padding-top: 118px;
        padding-left: 100px;
        padding-right: 50px;
        padding-bottom: 50px;
    }

    .packages-on-scroll {
        display: flex;
    }
}

[data-tooltip] {
    position: relative;
}

[data-tooltip]::before {
    content: attr(data-tooltip);
    position: absolute;
    bottom: -165%;
    z-index: 99;
    visibility: hidden;
    opacity: 0;
    background: #fff;
    border-radius: 25px;
    padding: 10px 18px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}

[data-tooltip]:hover::before {
    visibility: visible;
    opacity: 1;
    transition: opacity 0.5s;
}

