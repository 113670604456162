.register_div {
    margin: auto;
    padding: 20px;
    max-width: 500px;
}

.register_headline {
    font-weight: 400;
    margin: auto;
    justify-content: center;
}

.description_message {
    display: block;
    text-align: left;
    font-weight: 600;
    margin-bottom: 3%;
    font-weight: 500;
    line-height: 1.2;
    font-size: 1rem;
}

.register_instruction {
    margin-bottom: 3%;
    font-weight: 600;
    line-height: 1.3;
}

/* register Form */

.label_style {
    display: inline-block;
    margin-bottom: 0.5rem;
    margin-top: 1rem;
}

.register_div .form-group {
    text-align: left;
    width: 100%;
}

.register_div .form-control {
    width: 100%;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
    .register_div .form-control {
        transition: none
    }
}

.register_div .form-control::-ms-expand {
    background-color: transparent;
    border: 0
}

.register_div .form-control:focus {
    color: #495057;
    background-color: #fff;
    border-color: #80bdff;
    outline: 0;
    box-shadow: 0 0 0 .2rem rgba(0, 123, 255, 0.25)
}

.register_div .form-control::placeholder {
    color: #6c757d;
    opacity: 1
}

.register_div .form-control:disabled,
.register_div .form-control[readonly] {
    background-color: #e9ecef;
    opacity: 1
}

.register_div select.form-control:focus::-ms-value {
    color: #495057;
    background-color: #fff
}

.register_div .form-control-file,
.register_div .form-control-range {
    display: block;
    width: 100%
}

.selector_div {
    width: 105%;
}

.mobile_controller {
    display: flex;
}

.country_code {
    display: block;
    width: 55px;
}

.mobile_input {
    display: block;
    width: 100%;
    margin-left: 1rem;
}

.postal_div {
    display: block;
    width: 120px;
}

.address_div {
    display: block;
    width: 100%;
    margin-left: 1rem;
}

.register_div .custom-control-input {
    position: relative;
    display: inline-block;
    min-height: 1.5rem;
    padding-left: 1.5rem;
    margin-top: 1rem;
}

.terms_condition {
    display: block;
    margin-top: 1rem;
    margin-left: 2rem;
    font-weight: 400;
    font-size: 1rem;
}

.black_button_div {
    display: flex;
    justify-content: center;
    margin: 2rem;
    margin-bottom: 4rem;
}

.standardBtn_black {
    max-width: 250px;
    font-weight: bold;
    background-color: #111111;
    min-height: 50px;
    border: 0px solid transparent;
    width: 100%;
    color: #fff;
    text-align: center;
    font-size: 1rem;
    border-radius: 100px;
}

.btn:not(:disabled):not(.disabled) {
    cursor: pointer;
}

.is_invalid {
    padding-right: calc(1.5em + 0.75rem);
    background-image: url('https://img.icons8.com/emoji/48/null/cross-mark-emoji.png');
    background-repeat: no-repeat;
    background-position: center right calc(0.375em + 0.1875rem);
    background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
    width: 100%;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    border-color: #dc3545;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}